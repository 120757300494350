import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 400 375.513"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#A3CF9B",
      d: "M243.87 120.16H11.83c-4.42 0-8-3.58-8-8s3.58-8 8-8h232.04c4.42 0 8 3.58 8 8s-3.58 8-8 8M309.14 106.75c-1.8 0-3.61-.61-5.09-1.83l-18.47-15.25c-3.41-2.81-3.89-7.86-1.08-11.26 2.81-3.41 7.86-3.89 11.26-1.08l13.11 10.82 27.1-24.84c3.25-2.98 8.32-2.77 11.3.49 2.99 3.26 2.77 8.32-.49 11.3l-32.24 29.55a7.96 7.96 0 0 1-5.41 2.1Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#72c9e1",
      d: "M334.03 300.41H65.97C29.6 300.41 0 270.81 0 234.44V101.19c0-36.38 29.6-65.98 65.97-65.98H251.9c4.42 0 8 3.58 8 8s-3.58 8-8 8H65.97C38.41 51.21 16 73.63 16 101.18v133.26c0 27.56 22.42 49.97 49.97 49.97h268.06c27.56 0 49.97-22.42 49.97-49.97V83.98c0-4.42 3.58-8 8-8s8 3.58 8 8v150.46c0 36.38-29.6 65.97-65.97 65.97"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#73C3B6",
      d: "M316.02 167.95c-46.3 0-83.98-37.67-83.98-83.98S269.72 0 316.02 0 400 37.67 400 83.98s-37.67 83.98-83.98 83.98Zm0-151.95c-37.48 0-67.98 30.49-67.98 67.98s30.49 67.98 67.98 67.98S384 121.47 384 83.98 353.51 16 316.02 16"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#9d9d9c",
      d: "M99.37 261.84H66.06c-13.23 0-24-10.77-24-24v-4.06c0-13.23 10.77-24 24-24h33.31c13.23 0 24 10.77 24 24v4.06c0 13.23-10.77 24-24 24m-33.31-36.06c-4.41 0-8 3.59-8 8v4.06c0 4.41 3.59 8 8 8h33.31c4.41 0 8-3.59 8-8v-4.06c0-4.41-3.59-8-8-8z"
    }, null, -1)
  ])))
}
export default { render: render }