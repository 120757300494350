import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 152 122"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g fill=\"none\"><g fill=\"#9D9D9C\" transform=\"translate(32 58)\"><path d=\"M50.826 5.408a4.34 4.34 0 0 0-4.207-.144c-3.945 1.56-1.805 6.229-1.805 6.229 7.836 4.561 12.404 13.188 11.23 23.14-1.33 11.027-10.135 19.662-21.2 20.794C23.776 56.56 13.401 49.885 9.86 39.356s.693-22.104 10.2-27.872h-.045s1.715-4.002-1.183-6.003a4.65 4.65 0 0 0-5.308.261C2.177 13.954-2.55 28.55 1.832 41.842c4.383 13.29 16.87 22.227 30.884 22.102s26.339-9.284 30.483-22.65c4.144-13.368-.844-27.877-12.336-35.886z\"></path><path d=\"M37.863 22.616c0-2.65-2.41-4.795-5.417-4.795s-5.417 2.145-5.417 4.795v22.662c0 2.65 2.41 4.795 5.417 4.795s5.416-2.145 5.416-4.795z\"></path><ellipse cx=\"32.392\" cy=\"6.166\" rx=\"5.39\" ry=\"5.381\"></ellipse></g><path fill=\"#A3CF9B\" d=\"M115.441 88.078c-.04-21.056-12.753-39.998-32.174-47.936s-41.694-3.297-56.366 11.746a4.6 4.6 0 0 0-.027 6.487 4.555 4.555 0 0 0 6.46.027c12.055-12.442 30.417-16.304 46.43-9.765 16.014 6.538 26.482 22.173 26.47 39.532 0 2.538 2.048 4.596 4.576 4.596s4.577-2.058 4.577-4.596a.2.2 0 0 0 .054-.09\" class=\"ring-green\"></path><path fill=\"#73C3B6\" d=\"M134.286 87.327c.056-28.424-17.141-54.032-43.455-64.706-26.313-10.674-56.465-4.272-76.186 16.175a4.53 4.53 0 0 0 2.382 7.466 4.52 4.52 0 0 0 4.305-1.377c17.17-17.64 43.305-23.107 66.093-13.825s37.688 31.464 37.68 56.095v.905a4.594 4.594 0 0 0 4.378 4.8 4.593 4.593 0 0 0 4.794-4.791v-.09c0-.218.01-.408.01-.652\" class=\"ring-blue-green\"></path><path fill=\"#72C9E1\" d=\"M64.038 0C40.558-.019 18.06 9.448 1.61 26.266a4.68 4.68 0 0 0-.369 6.591 4.637 4.637 0 0 0 6.935 0C30.47 10.086 64.27 3.109 93.706 15.203S142.37 56.06 142.367 87.99a4.68 4.68 0 0 0 2.266 4.221 4.64 4.64 0 0 0 4.777 0 4.68 4.68 0 0 0 2.266-4.221C151.676 39.397 112.441.005 64.038 0\" class=\"ring-blue\"></path></g>", 1)
  ])))
}
export default { render: render }