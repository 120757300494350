import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "data-name": "Layer 1",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"none\" stroke=\"#72C9E1\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M23.5 27.5h-17l-1-15.19a.76.76 0 0 1 .77-.81H10a1.11 1.11 0 0 1 .89.44l1.22 1.56H23.5v2\"></path><path fill=\"none\" stroke=\"#72C9E1\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"m26.3 20.7.84-3.2H9.25l-2.75 10h16.91a1.42 1.42 0 0 0 1.37-1.06l.76-2.88\"></path><path fill=\"none\" stroke=\"#A3CF9B\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M16.5 24.5a1.42 1.42 0 0 1 2 0h0M13.5 21.5h1M20.5 21.5h1\"></path><path fill=\"none\" stroke=\"#73C3B6\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M20.62 3.61C18.25 4 16.5 5.37 16.5 7a2.57 2.57 0 0 0 .7 1.7l-.7 2.8 2.86-1.43a8.1 8.1 0 0 0 2.64.43c3 0 5.5-1.57 5.5-3.5 0-1.6-1.69-2.95-4-3.37\"></path><path fill=\"none\" stroke=\"#e17772\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"m21.25 6.25 1.5 1.5M22.75 6.25l-1.5 1.5\"></path>", 5)
  ])))
}
export default { render: render }